<template>
  <div>
    <b-img
      :src="skin === 'light' ? appLogoImage : appLogoImageDark"
      :alt="appName"
    />
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { BImg } from 'bootstrap-vue'
import { $themeConfig } from '../../../themeConfig'

export default {
  name: 'Logo',
  components: {
    BImg,
  },
  setup() {
    const { skin } = useAppConfig()

    // App Name
    const { appName, appLogoImage, appLogoImageDark } = $themeConfig.app

    return {

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      appLogoImageDark,
    }
  },
}
</script>

<style scoped>

</style>
