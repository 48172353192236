<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <div class="brand-logo auth-bg">
        <logo />
      </div>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5 overflow-hidden bg-white background-no-repeat bg-cover"
        :style="`background-image: url(${imgUrl}); background-position: center;`"
      />
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-lg-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('auth.forgot-password.header') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('auth.forgot-password.text') }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="requestPasswordReset"
            >
              <b-form-group
                :label="$t('auth.your-email')"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('auth.your-email')"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    autocomplete="email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
              >
                {{ $t('auth.forgot-password.recover-password') }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'login'}">
              <feather-icon icon="ChevronLeftIcon" />
              {{ $t('auth.back-to-log-in') }}
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton, BCardText, BCardTitle, BCol, BForm, BFormGroup, BFormInput, BLink, BRow,
} from 'bootstrap-vue'
import { email, required } from '@validations'
import store from '@/store'
import Logo from '@/views/_partials/Logo'
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    Logo,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      processing: false,
      sideImg: require('@/assets/images/pages/lessons/lesson_02.jpg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        // return this.sideImg
      }
      return this.sideImg
    },
  },
  beforeMount() {
    this.isUserLoggedIn()
  },
  methods: {
    isUserLoggedIn() {
      if (this.$store.state.auth.isUserLoggedIn()) {
        this.$router.push('/')
        return true
      }
      this.loading = false
      return false
    },
    requestPasswordReset() {
      this.$refs.simpleRules.validate()
        .then(valid => {
          if (!valid) {
            return false
          }
          this.processing = true
          this.$store.dispatch('auth/forgotPassword', this.userEmail)
            .finally(() => {
              this.processing = false
              window.toast.notify.success(this.$t('auth.forgot-password.request-sent'))
              router.push({ name: 'login' })
            })

          return true
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.auth-wrapper .brand-logo {
  border-radius: 0.357rem;
  img {
    width: 200px;
    height: inherit;
    margin: 30px;
  }
}
</style>
